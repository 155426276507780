import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const NotFoundPage = () => {
  const siteTitle = 'Onodera Ryo | 404'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <h1>NOT FOUND</h1>
    </Layout>
  )
}


export default NotFoundPage
